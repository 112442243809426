<template>
    <li class="dropdown" v-if="user">
        <a id="userSettings" aria-haspopup="true" class="user-settings" data-toggle="dropdown" href="#">
            <span class="user-name">{{ user.userName }}</span>
            <span class="avatar">
          <img alt="avatar" src="/img/avatar.png">
        </span>
        </a>
        <div aria-labelledby="userSettings" class="dropdown-menu dropdown-menu-right" v-if="user">
            <div class="header-profile-actions text-center">
                <div class="header-user-profile">
                    <div class="header-user">
                        <img alt="Admin Template" src="/img/avatar.png">
                    </div>
                    <h5>{{ user.userName }}</h5>
                    <p class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 hide-text">{{ user.companyName }}</p>
                    <p class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 red pointer font-weight-bold"
                       @click="logoutOut" style="background-color: #f1f1f1; color: red">Sair</p>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import {useI18n} from 'vue-i18n';

export default {
    props: ['user'],
    emits: [
        'logout',
        'update'
    ],

    setup() {
        const {t} = useI18n();
        return {t}
    },
    methods: {
        logoutOut()
        {
            this.$store.dispatch('actionSignOut');
        }
    }
}

</script>
<style>
.hide-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
