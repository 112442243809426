<template>
    <div>
        <loadingScreen :isLoading="isLoading"/>
        <div class="page-wrapper" id="wrapper">
            <nav id="sidebar" class="sidebar-wrapper">
                <div class="sidebar-brand">
                    <a>
                        <router-link to="/">
                            <img style="max-width: 250px; margin:auto; width: 176px; margin-top: 6px"
                                 alt="R2 Soft" src="@/assets/img/logo-pb_l.png">
                        </router-link>
                    </a>
                </div>
                <div class="sidebar-content" style="height: 100%;">
                    <menu-component/>
                </div>
            </nav>
            <div class="page-content">
                <header class="header">
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                        <div class="toggle-btns">
                            <a id="toggle-sidebar" style="border-radius: 5px;" href="#"
                               @click.stop.prevent="toggleSidebar()">
                                <i class="icon-list"/>
                            </a>
                            <a id="pin-sidebar" style="border-radius: 5px;" href="#" @click.stop.prevent="pinSidebar()">
                                <i class="icon-list"/>
                            </a>
                        </div>
                    </div>
                    <div v-if="haveData" class="text-dark bold">
                        <b>
                            {{ this.profile.companyName }}
<!--                            {{ this.profile.companyName + " - " + this.profile.description }}-->
                        </b>
                    </div>
                    <div class="col-xl-2 col-lg-4 col-md-5 col-sm-5 col-3">
                        <ul v-if="haveData" class="header-actions right-content">
                            <profile :user="profile"/>
                        </ul>
                    </div>
                </header>
                <div class="main-container">
                    <router-view>
                    </router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MenuComponent from '@/components/layouts/menuComponent';
import {mapGetters, mapState} from 'vuex';
import LoadingScreen from '@/components/layouts/loadScreenComponent';
import Helper from '@/Helpers/helper';
import Profile from '@/components/layouts/profile';
import moment from 'moment';
import Auth from '../services/auth';
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';

export default {
    name: 'AppLayout',
    components: {
        LoadingScreen,
        MenuComponent,
        Profile
    },

    mounted() {
        this.indexUser();
        this.initializeExpireAt();
    },
    unmounted() {
        clearInterval(this.intervalIdInitializeExpireAt);
        clearInterval(this.intervelCheckToken);
    },
    data() {
        return {
            intervalIdInitializeExpireAt: null,
            intervelCheckToken: null,
            profile: {},
            haveData: false,
            isCropping: false,
            beforeFive: false,
            document: {},
        }
    },
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    computed: {
        ...mapState({
            image: 'image',
            pinned: 'pinned',
            toggled: 'toggled',
            isLoading: 'isLoading',
            typeOfFind: 'type'
        }),
        ...mapGetters(['sessionStatus']),
    },

    methods: {
        initializeExpireAt() {
            if (localStorage.getItem('expire_at')) {
                clearInterval(this.intervalIdInitializeExpireAt);
                clearInterval(this.intervelCheckToken);
                this.setCheckTokenInterval();
            } else {
                this.intervalIdInitializeExpireAt = setInterval(this.initializeExpireAt, 500);
            }
        },
        setCheckTokenInterval()
        {
            this.intervelCheckToken = setInterval(() => {
                const expireAt = localStorage.getItem('expire_at');
                let dateEnd = moment(expireAt).subtract(2, "minutes")
                        .format("YYYY-MM-DD HH:mm:ss");
                let currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
                let dateEndSub = moment(dateEnd).subtract(5, "minutes")
                        .format("YYYY-MM-DD HH:mm:ss");
                if (moment(currentDate).isAfter(dateEndSub)) {
                    if (moment(currentDate).isAfter(dateEnd)) {
                        clearInterval(this.intervelCheckToken);
                        this.$swal?.close();
                        this.$store.dispatch('actionSignOut');
                        return;
                    }
                    this.$swal.fire({
                        title: this.t("HOME.SESSION_IS_EXPIRED_DID_YOU_RENOVE"),
                        showCancelButton: true,
                        showCloseButton: true,
                        confirmButtonText: 'Renovar',
                        cancelButtonText: 'Cancelar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Auth.renewToken().then((resp) => {
                                localStorage.setItem('access_token', resp.data.access_token);
                                localStorage.setItem('expire_at', resp.data.expire_at);
                            }).catch(() => {});
                        }
                    });
                }
            }, 100000);
        },
        async indexUser() {
            let userName = localStorage.getItem('user_name');
            if (!userName) {
                this.haveData = false;
            } else {
                this.haveData = true;
                this.profile = {
                    userName: localStorage.getItem('user_name'),
                    companyName: localStorage.getItem('company_name'),
                    description: localStorage.getItem('description_company')
                }
            }
        },

        viewDocument(document_base6) {
            Helper.viewDocument(document_base6);
        },

        pinSidebar() {
            if (this.pinned) {
                document.getElementById('wrapper').classList.remove('pinned');
            } else {
                document.getElementById('wrapper').classList.add('pinned');
                document.getElementById('configSubmenu').classList.remove('show');
            }

            this.$store.commit('pinSidebar');
        },

        toggleSidebar() {
            if (this.toggled) {
                document.getElementById('wrapper').classList.remove('toggled');
            } else {
                document.getElementById('wrapper').classList.add('toggled');
                document.getElementById('configSubmenu').classList.remove('show');
            }

            this.$store.commit('toggleSidebar');
        },
    }
};

</script>


<style lang="less">
.size {
    width: 200px !important;
}

.size-modal {
    width: 100% !important;
}

.btn-background {
    width: 100%;
    font-size: large !important;
    font-weight: 600 !important;
    background-color: #adadad !important;
}

.modal-dialog {
    overflow-y: initial !important
}

.modal-row2 {
    max-height: 40vh;
    overflow-y: auto;
}

.modal-row {
    max-height: 40vh;
    overflow-y: auto;
}

.modal-row2::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row2::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-header-color {
    color: white;
    background-color: #0e5e4e !important;
}

.right-content {
    justify-content: flex-end;
}

.swal2-container {
    z-index: 100000000000;
}

.user-avatar {
    max-width: 80px !important;
    max-height: 80px !important;
    border-radius: 50% !important;
    margin-bottom: 1.2rem !important;
}

.avatar-size {
    width: 80px !important;
    height: 80px !important;
}

.cropper-size {
    max-width: 350px !important;
    max-height: 275px !important;
}

.avatar .status {
    top: 62px;
    right: 2px;
    width: 25px;
    height: 25px;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    border: 4px solid #f0f0f0;
}

.camera {
    right: 1;
    bottom: 0;
    outline: 0;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    background-color: #f0f0f0;
}

.align-items {
    align-items: center;
}

.preview {
    border: dashed 1px rgba(255, 255, 255, 0.25);
}

.cropper {
    height: 100%;
    background: #DDD;
}

.hearder-custom {
    color: #333333 !important;
    background: #ffffff !important;
}

.close-custom {
    color: #000 !important;
}

.border-bottom {
    border-bottom: 1px solid #e6ebf1;
}

.border-top {
    background-color: #f2f4f9;
    border-top: 1px solid #e6ebf1;
}

.lg {
    max-width: 800px !important;
}
</style>

