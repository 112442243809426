<script>
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";

export default {
  name: "Validate",

  setup() {
    const toast = useToast();
    const {t} = useI18n();
    return {t, toast};
  },

  data() {
    return {
      cep: null,
      name: null,
      msgCep: "",
      invalid: [],
      msgError: "",
      msgEmail: "",
      msgBirthday: "",
      msgSex: "",
      isBirthdayInvalid: false,
      msgDispatchingAgency: "",
      isDispatchingAgencyInvalid: false,
      msgDispatchDate: "",
      msgTaxpayer: "",
      msgPassword: "",
      isNameInvalid: false,
      finalAbaDisable: null,
      secondAbaDisable: null,
      isTaxpayerInvalid: false,
      msgPhone: "GENERAL.MSG.REQUIRED_FIELD",
    };
  },

  methods: {
    nameValidate(name) {
      if (!name) {
        this.isNameInvalid = true;
        this.invalid.push(this.isNameInvalid);
        this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
        document.getElementById("name").classList.add("is-invalid");
      } else if (name.length < 3) {
        this.isNameInvalid = true;
        this.invalid.push(this.isNameInvalid);
        this.msgError = "GENERAL.MSG.MINIMUM_CHARACTER";
        document.getElementById("name").classList.add("is-invalid");
      } else if (name.length > 255) {
        this.isNameInvalid = true;
        this.invalid.push(this.isNameInvalid);
        this.msgError = "GENERAL.MSG.MAXIMUM_CHARACTER";
        document.getElementById("name").classList.add("is-invalid");
      } else {
        this.isNameInvalid = false;
        document.getElementById("name").classList.add("is-valid");
      }
    },

    corporateNameValidate(name) {
      if (!name) {
        this.isNameInvalid = true;
        this.invalid.push(this.isNameInvalid);
        this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
        document.getElementById("corporate_name").classList.add("is-invalid");
      } else if (name.length < 3) {
        this.isNameInvalid = true;
        this.invalid.push(this.isNameInvalid);
        this.msgError = "GENERAL.MSG.MINIMUM_CHARACTER";
        document.getElementById("corporate_name").classList.add("is-invalid");
      } else if (name.length > 255) {
        this.isNameInvalid = true;
        this.invalid.push(this.isNameInvalid);
        this.msgError = "GENERAL.MSG.MAXIMUM_CHARACTER";
        document.getElementById("corporate_name").classList.add("is-invalid");
      } else {
        this.isNameInvalid = false;
        document.getElementById("corporate_name").classList.add("is-valid");
      }
    },

    inputName(name) {
      this.isNameInvalid = false;
      this.finalAbaDisable = true;
      this.secondAbaDisable = true;
      document.getElementById("name").classList.remove("is-valid");
      document.getElementById("name").classList.remove("is-invalid");
      this.nameValidate(name);
    },

    inputCorporateName(name) {
      this.isNameInvalid = false;
      this.finalAbaDisable = true;
      this.secondAbaDisable = true;
      document.getElementById("corporate_name").classList.remove("is-valid");
      document.getElementById("corporate_name").classList.remove("is-invalid");
      this.corporateNameValidate(name);
    },

    inputTaxpayer(taxpayer) {
      this.isTaxpayerInvalid = false;
      document.getElementById("taxpayer").classList.remove("is-valid");
      document.getElementById("taxpayer").classList.remove("is-invalid");
      this.validateTaxpayer(taxpayer);
    },

    inputCep(cep) {
      document.getElementById("cep").classList.remove("is-valid");
      document.getElementById("cep").classList.remove("is-invalid");
      this.validateCep(cep);
    },

    validateCep(cep) {
      if (!cep) {
        this.invalid.push(true);
        this.msgCep = this.t('GENERAL.MSG.REQUIRED_FIELD');
        document.getElementById("cep").classList.add("is-invalid");
        document.getElementById("invalidCep").style.display = "block";
      } else if (cep.length != 9) {
        this.invalid.push(true);
        this.msgCep = this.t('GENERAL.MSG.CEP_INVALID');
        document.getElementById("cep").classList.add("is-invalid");
        document.getElementById("invalidCep").style.display = "block";
      } else {
        document.getElementById("cep").classList.add("is-valid");
        document.getElementById("invalidCep").style.display = "none";
      }
    },

    validateDispatchingAgency(dispatchingAgency) {
      if (dispatchingAgency.length > 15) {
        this.isDispatchingAgencyInvalid = true;
        this.invalid.push(true);
        this.msgDispatchingAgency = this.t('GENERAL.MSG.DISPATCHING_AGENCY_INVALID');
        document.getElementById('dispatching_agency').classList.add("is-invalid");
        document.getElementById('invalidDispatching_agency').style.display = "block";
      } else if (dispatchingAgency.length < 15) {
        if (dispatchingAgency.length === 0) {
          document.getElementById("invalidDispatching_agency").style.display = "none";
        } else {
          this.isDispatchingAgencyInvalid = false;
          document.getElementById("dispatching_agency").classList.add("is-valid");
          document.getElementById("invalidDispatching_agency").style.display = "none";
        }
      }
    },

    validateBirthday(birthday) {
      if (!birthday) {
        this.isBirthdayInvalid = true;
        this.invalid.push(this.isBirthdayInvalid);
        this.msgBirthday = this.t("GENERAL.MSG.REQUIRED_FIELD");
        document.getElementById("birthday").classList.add("is-invalid");
      } else if (birthday.length === 10) {
        this.isBirthdayInvalid = false;
        document.getElementById('birthday').classList.add("is-valid");
      } else {
        this.isBirthdayInvalid = true;
        this.invalid.push(this.isBirthdayInvalid);
        this.msgBirthday = this.t("GENERAL.MSG.DATE_INVALID");
        document.getElementById("birthday").classList.add("is-invalid");
      }
    },

    validateFoundationDay(foundationDay) {
      if (foundationDay) {
        if (foundationDay.length === 10) {
          document.getElementById('foundationDay').classList.add('is-valid');
          document.getElementById('invalidFoundationDay').style.display = 'none';
        } else {
          this.invalid.push(true);
          this.msgBirthday = this.t("GENERAL.MSG.DATE_INVALID");
          document.getElementById("foundationDay").classList.add("is-invalid");
          document.getElementById("invalidFoundationDay").style.display = 'block';
        }
      } else {
        this.client.birthday = null;
      }
    },

    validateDispatchDate(dispatchDate) {
      if (dispatchDate) {
        if (dispatchDate.length > 10) {
          this.invalid.push(true);
          this.msgDispatchDate = this.t('GENERAL.MSG.DATE_INVALID');
          document.getElementById('dispatch_date').classList.add("is-invalid");
          document.getElementById('invalidDispatch_date').style.display = "block";
        } else {
          document.getElementById("dispatch_date").classList.add("is-valid");
          document.getElementById("invalidDispatch_date").style.display = "none";
        }
      }
    },

    validateTaxpayer(taxpayer) {
      if (!taxpayer) {
        this.isTaxpayerInvalid = true;
        this.invalid.push(this.isTaxpayerInvalid);
        this.msgTaxpayer = "GENERAL.MSG.REQUIRED_FIELD";
        document.getElementById("taxpayer").classList.add("is-invalid");
      } else if (taxpayer.length == 14 || taxpayer.length == 18) {
        if (this.validateCNPJ(taxpayer) || this.validateCpf(taxpayer)) {
          this.isTaxpayerInvalid = false;
          document.getElementById("taxpayer").classList.add("is-valid");
        } else {
          this.isTaxpayerInvalid = true;
          this.invalid.push(this.isTaxpayerInvalid);
          this.msgTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
          document.getElementById("taxpayer").classList.add("is-invalid");
        }
      } else {
        this.isTaxpayerInvalid = true;
        this.invalid.push(this.isTaxpayerInvalid);
        this.msgTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
        document.getElementById("taxpayer").classList.add("is-invalid");
      }
    },

    validateGeneric(input, id, isInput = true) {
      if (!input) {
        this.invalid.push(true);
        document.getElementById("invalid" + id).style.display = "block";
        if (isInput) {
          document.getElementById(id).classList.add("is-invalid");
        }
      } else {
        if (isInput) {
          document.getElementById(id).classList.add("is-valid");
        }
        document.getElementById("invalid" + id).style.display = "none";
      }
    },

    inputGeneric(input, id) {
      document.getElementById(id).classList.remove("is-valid");
      document.getElementById(id).classList.remove("is-invalid");
      document.getElementById("invalid" + id).style.display = "none";
      this.validateGeneric(input, id);
    },

    selectGeneric(select, id) {
      document.getElementById("invalid" + id).style.display = "none";
      this.validateGeneric(select, id, false);
    },

    validatePhone(input, id) {
      if (input && (input.length == 14 || input.length == 15)) {
        document.getElementById(id).classList.add("is-valid");
        document.getElementById("invalid" + id).style.display = "none";
      } else {
        this.invalid.push(true);
        document.getElementById("invalid" + id).style.display = "block";
        document.getElementById(id).classList.add("is-invalid");
        this.msgPhone = !input
            ? "GENERAL.MSG.REQUIRED_FIELD"
            : "GENERAL.MSG.PHONE_INVALID";
      }
    },

    inputPhone(input, id) {
      document.getElementById(id).classList.remove("is-valid");
      document.getElementById(id).classList.remove("is-invalid");
      document.getElementById("invalid" + id).style.display = "none";
      this.validatePhone(input, id);
    },

    validateSex(sex){
      if(!sex){
        this.invalid.push(true);
        this.msgSex = this.t("GENERAL.MSG.REQUIRED_FIELD");
        document.getElementById('sex').classList.add("is-invalid");
        document.getElementById("invalidSex").style.display = "block";
      }else {
        document.getElementById('sex').classList.add('is-valid');
        document.getElementById('invalidSex').style.display = "none";
      }
    },

    emailValidate(email) {
      if (!email) {
        this.invalid.push(true);
        this.msgEmail = this.t("GENERAL.MSG.REQUIRED_FIELD");
        document.getElementById("email").classList.add("is-invalid");
        document.getElementById("invalidEmail").style.display = "block";
      } else if (!this.validEmail(email)) {
        this.invalid.push(true);
        this.msgEmail = this.t("GENERAL.MSG.EMAIL_INVALID");
        document.getElementById("email").classList.add("is-invalid");
        document.getElementById("invalidEmail").style.display = "block";
      } else if (email.length > 255) {
        this.invalid.push(true);
        this.msgEmail = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
        document.getElementById("email").classList.add("is-invalid");
        document.getElementById("invalidEmail").style.display = "block";
      } else {
        document.getElementById("email").classList.add("is-valid");
        document.getElementById("invalidEmail").style.display = "none";
      }
    },

    emailValidateForClient(email) {
      if (!email) {
        this.invalid.push(true);
      } else if (!this.validEmail(email)) {
        this.invalid.push(true);
        this.msgEmail = this.t("GENERAL.MSG.EMAIL_INVALID");
        document.getElementById("email").classList.add("is-invalid");
        document.getElementById("invalidEmail").style.display = "block";
      } else if (email.length > 255) {
        this.invalid.push(true);
        this.msgEmail = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
        document.getElementById("email").classList.add("is-invalid");
        document.getElementById("invalidEmail").style.display = "block";
      } else {
        document.getElementById("email").classList.add("is-valid");
        document.getElementById("invalidEmail").style.display = "none";
      }
    },

    validEmail(email) {
      var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    inputEmail(email) {
      document.getElementById("invalidEmail").style.display = "none";
      document.getElementById("email").classList.remove("is-valid");
      document.getElementById("email").classList.remove("is-invalid");
      this.emailValidate(email);
    },

    inputEmailForClient(email) {
      document.getElementById("invalidEmail").style.display = "none";
      document.getElementById("email").classList.remove("is-valid");
      document.getElementById("email").classList.remove("is-invalid");
      this.emailValidateForClient(email);
    },

    inputDispatchingAgency(dispatchingAgency) {
      document.getElementById("invalidDispatching_agency").style.display = "none";
      document.getElementById("dispatching_agency").classList.remove("is-valid");
      document.getElementById("dispatching_agency").classList.remove("is-invalid");
      this.validateDispatchingAgency(dispatchingAgency);
    },

    inputDispatchDate(dispatchDate) {
      document.getElementById("invalidDispatch_date").style.display = "none";
      document.getElementById("dispatch_date").classList.remove("is-valid");
      document.getElementById("dispatch_date").classList.remove("is-invalid");
      this.validateDispatchDate(dispatchDate);
    },

    inputBirthday(birthday) {
      this.isBirthdayInvalid = false;
      document.getElementById("birthday").classList.remove("is-valid");
      document.getElementById("birthday").classList.remove("is-invalid");
      this.validateBirthday(birthday);
    },

    inputFoundationDay(foundationDay) {
      document.getElementById("invalidFoundationDay").style.display = "none";
      document.getElementById("foundationDay").classList.remove("is-valid");
      document.getElementById("foundationDay").classList.remove("is-invalid");
      this.validateFoundationDay(foundationDay);
    },

    inputSex(sex){
      this.validateSex(sex);
    },

    passwordValidade(password, passwordConfirm, isSingup = true) {
      if (!password) {
        this.invalid.push(true);
        this.msgPassword = this.t("GENERAL.MSG.REQUIRED_FIELD");
        document.getElementById("password").classList.add("is-invalid");
        document.getElementById("invalidPassword").style.display = "block";
      }

      if (isSingup && !passwordConfirm) {
        this.invalid.push(true);
        this.msgPassword = this.t("GENERAL.MSG.REQUIRED_FIELD");
        document.getElementById("invalidPassword").style.display = "block";
        document.getElementById("passwordConfirm").classList.add("is-invalid");
      }

      this.validateEqualityPassword(password, passwordConfirm);
    },

    validateEqualityPassword(password, passwordConfirm) {
      if (password != passwordConfirm) {
        this.invalid.push(true);
        this.msgPassword = this.t("GENERAL.MSG.DIFFERENT_PASSWORDS");
        document.getElementById("password").classList.add("is-invalid");
        document.getElementById("invalidPassword").style.display = "block";
        document.getElementById("passwordConfirm").classList.add("is-invalid");
      } else {
        this.passwordStrength(password);

        if (this.invalid.length == 0) {
          document.getElementById("password").classList.add("is-valid");
          document.getElementById("passwordConfirm").classList.add("is-valid");
        }
      }
    },

    inputPassword(password, passwordConfirm, isSingup = true) {
      document.getElementById("invalidPassword").style.color = "red";
      document.getElementById("password").classList.remove("is-valid");
      document.getElementById("invalidPassword").style.display = "none";
      document.getElementById("password").classList.remove("is-invalid");
      document.getElementById("passwordConfirm").classList.remove("is-valid");
      document.getElementById("passwordConfirm").classList.remove("is-invalid");
      this.passwordValidade(password, passwordConfirm, isSingup);
    },

    passwordStrength(password) {
      var numbers = /([0-9])/;
      var alphabet = /([a-zA-Z])/;
      var spCharacter = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

      if (password) {
        if (password.length < 8) {
          this.invalid.push(true);
          this.msgPassword = this.t("GENERAL.MSG.PASSWORD_TO_SMALL");
          document.getElementById("password").classList.add("is-invalid");
          document.getElementById("invalidPassword").style.display = "block";
          document
              .getElementById("passwordConfirm")
              .classList.add("is-invalid");
        } else if (password.length > 20) {
          this.invalid.push(true);
          this.msgPassword = this.t("GENERAL.MSG.PASSWORD_TO_LONG");
          document.getElementById("password").classList.add("is-invalid");
          document.getElementById("invalidPassword").style.display = "block";
          document
              .getElementById("passwordConfirm")
              .classList.add("is-invalid");
        } else {
          if (
              password.match(numbers) &&
              password.match(alphabet) &&
              password.match(spCharacter)
          ) {
            this.msgPassword = this.t("GENERAL.MSG.PASSWORD_STRONG");
            document.getElementById("invalidPassword").style.color = "green";
            document.getElementById("invalidPassword").style.display = "block";
          } else if (
              password.match(alphabet) &&
              password.match(numbers) &&
              !password.match(spCharacter)
          ) {
            this.msgPassword = this.t("GENERAL.MSG.PASSWORD_MEDIUM");
            document.getElementById("invalidPassword").style.color = "orange";
            document.getElementById("invalidPassword").style.display = "block";
          } else if (
              (password.match(alphabet) &&
                  !password.match(numbers) &&
                  !password.match(spCharacter)) ||
              (!password.match(alphabet) &&
                  password.match(numbers) &&
                  !password.match(spCharacter))
          ) {
            this.invalid.push(true);
            this.msgPassword = this.t("GENERAL.MSG.PASSWORD_WEAK");
            document.getElementById("password").classList.add("is-invalid");
            document.getElementById("invalidPassword").style.display = "block";
            document
                .getElementById("passwordConfirm")
                .classList.add("is-invalid");
          }
        }
      }
    },

    errorMsg(error) {
      const erro = error.response.data.validations.errors;

      if (erro.sql || !erro) {
        this.toast.error(error.response.data.validations.message, {
          timeout: false,
        });
      } else {
        for (var column in erro) {
          for (var msg in erro[column]) {
            this.toast.error(erro[column][msg], {timeout: false});
          }
        }
      }
    },

    validateMoney3(value) {
      if (value % 2 == 0) {
        return value * 100;
      } else {
        return value;
      }
    },

    validateCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) return false;

      if (
          cnpj == "00000000000000" ||
          cnpj == "11111111111111" ||
          cnpj == "22222222222222" ||
          cnpj == "33333333333333" ||
          cnpj == "44444444444444" ||
          cnpj == "55555555555555" ||
          cnpj == "66666666666666" ||
          cnpj == "77777777777777" ||
          cnpj == "88888888888888" ||
          cnpj == "99999999999999"
      )
        return false;

      let size = cnpj.length - 2;
      let numbers = cnpj.substring(0, size);
      let digits = cnpj.substring(size);
      let sum = 0;
      let pos = size - 7;

      for (var i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
      }

      let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (resultado != digits.charAt(0)) return false;

      size = size + 1;
      numbers = cnpj.substring(0, size);
      sum = 0;
      pos = size - 7;

      for (i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;

        if (pos < 2) pos = 9;
      }

      resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);

      if (resultado != digits.charAt(1)) return false;

      return true;
    },

    validateCpf(cpf) {
      return validar(cpf);
    },

    widthPhone(size = 480, smaller = '<') {
      if (smaller == '<') {
        return window.innerWidth < size;
      } else {
        return window.innerWidth > size;
      }
    }
  },
};

const validar = (cpf) => checkAll(prepare(cpf));

const notDig = (i) => ![".", "-", " "].includes(i);
const prepare = (cpf) => cpf.trim().split("").filter(notDig).map(Number);
const is11Len = (cpf) => cpf.length === 11;
const notAllEquals = (cpf) => !cpf.every((i) => cpf[0] === i);
const onlyNum = (cpf) => cpf.every((i) => !isNaN(i));

const calcDig = (limit) => (a, i, idx) => a + i * (limit + 1 - idx);
const sumDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0);
const resto11 = (sumDig) => 11 - (sumDig % 11);
const zero1011 = (resto11) => ([10, 11].includes(resto11) ? 0 : resto11);

const getDV = (cpf, limit) => zero1011(resto11(sumDig(cpf, limit)));
const verDig = (pos) => (cpf) => getDV(cpf, pos) === cpf[pos];

const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)];
const checkAll = (cpf) => checks.map((f) => f(cpf)).every((r) => !!r);
</script>