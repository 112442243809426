<template>
    <div
            data-cy="menu_left_div"
            class="sidebar-menu modal-row"
            @mouseover="openSidebar()"
            @mouseleave="closeSidebar()">
        <div
                class="sidebar-menu"
                style="direction:ltr;">
            <ul id="configSubmenu">
                <li
                        id="Home"
                        :class="setMenu('Home')">
                    <router-link to="/">
                        <i
                                class="icon-home2"/>
                        <span
                                class="menu-text">{{ t('DOCUMENTS.MENU.HOME') }}</span>
                    </router-link>
                </li>
                <li
                        id="all"
                        :class="setMenu('All')">
                    <router-link to="/all">
                        <i
                                class="icon-documents"/>
                        <span class="menu-text">{{ t('DOCUMENTS.MENU.ALL') }}</span>
                    </router-link>
                </li>
                <li
                        id="waiting"
                        :class="setMenu('Running')">
                    <router-link to="/running">
                        <i
                                class="icon-clock1"/>
                        <span class="menu-text">{{ t('DOCUMENTS.MENU.WAITING') }}
            </span>
                    </router-link>
                </li>
                <li
                        id="Signed"
                        :class="setMenu('Signed')">
                    <router-link to="/signed">
                        <i
                                class="icon-check"/>
                        <span class="menu-text">{{ t('DOCUMENTS.MENU.SIGNEDS') }}</span>
                    </router-link>
                </li>
                <li
                        id="canceled"
                        :class="setMenu('Canceled')">
                    <router-link to="/canceled">
                        <i
                                class="icon-cancel"/>
                        <span class="menu-text">{{ t('DOCUMENTS.MENU.CANCELED') }}</span>
                    </router-link>
                </li>
                <li
                        id="expired"
                        :class="setMenu('Expired')">
                    <router-link to="/expired">
                        <i
                                class="icon-cached"/>
                        <span class="menu-text">{{ t('DOCUMENTS.MENU.EXPIRED') }}</span>
                    </router-link>
                </li>
                <li
                        id="pendingAdm" title="Pendentes Para Administração"
                        :class="setMenu('PendingAdm')">
                    <router-link to="/pendentes-adm">
                        <i
                                class="icon-recent_actors"/>
                        <span class="menu-text">{{ t('DOCUMENTS.MENU.PENDING_ADM') }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import Validate from '../validate.vue';

export default {
    name: 'MenuComponent',
    props: ['companyId'],
    mixins: [Validate],
    setup() {
        const {t} = useI18n();
        return {t,}
    },

    data() {
    },

    mounted() {
    },

    methods: {

        setMenu(name) {
            return {'active-page-link': this.$route.name == name};
        },

        openSidebar() {
            if (this.pinned) {
                document.getElementById('wrapper').classList.add('sidebar-hovered');
            }
        },

        closeSidebar() {
            if (this.pinned) {
                document.getElementById('configSubmenu').classList.remove('show');
                document.getElementById('wrapper').classList.remove('sidebar-hovered');
            }
        },
    }
}
</script>
<style
        lang="scss">
.modal-row {
    max-height: 100% !important;
    overflow-y: hidden;
    direction: rtl;
}

.modal-row::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row::-webkit-scrollbar-thumb {
    background: #212529;
    border-radius: 10px;
}
</style>
